<template>
    <!-- 新建课程页面 -->
    <div class="positionT0L0">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="{ span: 2 }" :wrapper-col="{ span: 7 }">
            <a-form-model-item label="分享海报：" prop="imageAry" :wrapper-col="{ span: 18 }" v-if="isShow">
                <UpdataImg @avatarfns="imgUrlFn" :imageUrlNum="10" :imgUrl="form.imageAry" :urlSize="500"></UpdataImg>
                <a-button style="margin-top: 10px" type="primary" @click="reorderClick">重新排序</a-button>
            </a-form-model-item>

            <a-form-model-item label="重新排序：" v-else>
                <div class="listBox">
                    <draggable v-model="list" @update="datadragEnd" :options="{animation:500}">
                        <li v-for="(item, index) in list" :key="index" class="listClass">
                            <img :src="item" alt="">
                        </li>
                    </draggable>
                </div>
                <a-button style="margin-top: 10px;margin-left: 6px" @click="isShow = true">取消</a-button>
            </a-form-model-item>
        </a-form-model>

        <FooterToolBar :collapsed="sideCollapsed">
            <a-button class="margin_right60" @click="() => $router.go(-1)">返回</a-button>
            <a-button type="primary" @click="keepClick" :loading="keepLoading" :disabled="keepLoading">保存</a-button>
        </FooterToolBar>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import {baseMixin} from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import {message} from "ant-design-vue";
import UpdataImg from "@/components/file/updataImg";
import {AddCoursePlaybill,GetCoursePlaybill} from "@/request/api/coursManage";

export default {
    mixins: [baseMixin],
    components: {draggable, FooterToolBar,UpdataImg },
    watch: {
        $route: {
            handler(to) {
                if (to.name == "PosterManage") {
                    document.body.scrollTop = 0;
                }
            },
            immediate: true,
        },
    },
    data() {
        return {
            isShow:true,
            keepLoading: false, // 保存的按钮是否加载

            form: {
                businessNo: this.$route.params.id == 0 ? '' : this.$route.params.id,
                imageAry: [],
            },

            rules: {
                imageAry:[{ required: true, message: "请上传海报" }]
            },

            list: [],
        };
    },
    created() {
        this.init()
    },
    methods: {
        init(){
            GetCoursePlaybill({businessNo:this.$route.params.id}).then(({code,data}) => {
                if (code == 200) {
                    if(data){
                        for(let i = 0; i<data.length;i++){
                            this.form.imageAry.push(data[i].ImageUrl)
                            this.list = this.form.imageAry
                        }
                    }
                }
            });
        },

        reorderClick(){
            this.isShow = false
            this.list = this.form.imageAry
        },

        datadragEnd (evt) {
            evt.preventDefault();
            this.form.imageAry = this.list
        },

        // 上传回调
        imgUrlFn(data) {
            this.form.imageAry = data
        },

        // 保存的按钮
        keepClick() {
            this.keepLoading = true;
            setTimeout(() => {
                this.keepLoading = false;
            }, 3000);
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    AddCoursePlaybill(this.form).then(({code}) => {
                        if (code == 200) {
                            message.success("编辑成功");
                            this.$router.push("/coursManage/courseList");
                        }
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
    .listClass{
        width: 102px;
        height: 102px;
      border: 1px solid rgba(9, 9, 9, 0.07);
      padding: 5px;
      margin-right: 15px;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .listBox{
        display: flex;
        >div{
            display: flex;
        }
    }
</style>
